import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { EModal } from '@contract/modal';
let NoNicknameSetModal = class NoNicknameSetModal extends AbstractModal {
    openSetNicknameModal() {
        this.swapModal(EModal.SetNickname);
    }
};
NoNicknameSetModal = __decorate([
    Component({ name: 'NoNicknameSet' })
], NoNicknameSetModal);
export { NoNicknameSetModal };
export default NoNicknameSetModal;
